import React from 'react';
import { navigate } from 'gatsby-link';

const ProductsPage = ({ pageContext: { url } }) => {
  if (typeof window !== 'undefined') {
    navigate(url);
  }

  return <div />;
};

export default ProductsPage;
